import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const SettingGoals = () => (
  <Layout>
    <SEO
      title="Setting Goals and Working With Your Subconscious"
      description="When I want to set a long term goal, I try to break it down and phrase it in such a way that I can repeat to myself easily. Ideally it should have three points or less so there is very little chance of me forgetting"
    />
    <h1>Setting Goals and Working With Your Subconscious</h1>
    <p>When I want to set a long term goal, I try to break it down and phrase it in such a way that I can repeat to myself easily. Ideally it should have three points or less so there is very little chance of me forgetting. My idea is that I’m not only trying to make this easy for me to remember but I also want it to sink into my subconscious too.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/g92v7KJ.jpg"
        alt="Setting Goals and Working With Your Subconscious"
        description="Woman gazing off into the distance contemplating something"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@chalis007"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      胡 卓亨
      </a>
      {" "} on Unsplash
    </p>

    <p>When it’s in there, I have found that I will take actions towards completing the goal I have set without much effort. I will solve problems when I’m doing everyday tasks, ideas will come to me surrounding the goal and occasionally I will dream about that goal.</p>
    <p>I had been using this technique consciously for a while. I wanted to look deeper into why it works or at least make it work better. The most common way I’ve heard this used is when people are stuck on a problem that seems easy but for some reason they can’t get past it. If they go on a walk or do something completely unrelated for a short while, the solution to the problem will just appear.</p>
    <p>I think my technique and this one I just described are pretty much the same thing. Letting your subconscious take the wheel. I believe that the subconscious is far less restricted than the conscious mind. It can wade through a far higher percentage of all the information we take in on a daily basis, going back further in time with high accuracy.</p>

    <h2>Making Friends With It</h2>
    <p>However you see your subconscious now, it can be used as a tool to benefit your life. Just as I’ve mentioned above, I work with it to get things done but it wasn’t always this way. Depending on who you are as a person, this might be the same for you.</p>
    <p>For difficult tasks that have little benefit to me in the short or long term, my subconscious would rather me do something else. It will find any kind of distraction to remind me of to put me off the task. Point out my phone that is a short distance away, point out the chrome browser where YouTube is a few clicks away or point out that if I go to the kitchen, there are a few snacks in the cupboard with my name on them.</p>
    <p>Then there’s the other side where when I’m doing a task where the benefits are obvious, again either short or long term. My subconscious or this time the voice in my head is right there to help me. I’m reaching my limit of 10 reps of pull ups and I’m ready to finish my set and the voice says, “two more”. When I’m done with my gym session 10 minutes early and about to leave then the voice says, “no, not yet, go and do some abs”.</p>
    <p>I am not sure how this voice appears, it’s something that developed in me and has been around for as long as I can remember but I do know for sure that it can be influenced and trained.</p>

    <h2>Diet and Weight Loss Example</h2>
    <p>A big example of this in action for me is when it comes to diet and weight management. Let’s say I want to lose 6kg. I have a conversation about what it will take to get there then I write it down. I have experience in the area so I can set realistic goals which always helps. The goals are always specific and time based which makes them measurable. This way I know exactly where I stand on the way towards them. So I reach a time period of 3 months. That’s 2kg a month now.</p>
    <p>When I write it down, I study it and come up with ways to get there. Exercise is something I already do and I’m not going to do more because it’s unnecessary. The next thing to look at is my diet. I decided to eat less.</p>
    <p>Here comes the tricky part and the single thing that most people stumble on. I understand that a “diet” is not going to produce long term results. Yes, you might lose the weight now but following a diet will probably leave you hungry and as soon as you reach your goal or get anywhere near it, you might just relax and go right back to your old diet. Leaving you right back where you started.</p>
    <p>This is where convincing your subconscious to help me comes in. If I can convince it that eating less is the right thing to do and a permanent change I must make for the betterment of myself then I never have to worry about my diet again. I won’t be fighting my own will but going right alongside it. That sounds perfect to me.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default SettingGoals
